import { useEffect, useState } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

const PublicContainer = ({ children, isSingup, tabTitle }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      await fetchUserAttributes();
      setIsLoading(false);
      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!isSingup) {
      checkAuth();
    }
  }, []);

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  if (isLoading) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default PublicContainer;
