import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { signUp } from "aws-amplify/auth";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Typography,
  Link,
  Checkbox,
  Grid,
  CircularProgress,
  FormControlLabel,
  Badge,
} from "@mui/material";
import FormField from "../../assets/theme/overrides/FormField";
import { PrimaryButton } from "../../assets/theme/overrides/button";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit."
    ),
});

const initialValues = {
  firstName: "",
  email: "",
  password: "",
};

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "20px",
  padding: "30px 20px",
  width: "100%",
  maxWidth: "500px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "260px",
    padding: "24px 12px",
  },
}));

export default function Signup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    // console.log("values", values);
    try {
      setIsLoading(true);
      const data = await signUp({
        username: values.email,
        password: values.password,
        options: {
          userAttributes: {
            email: values.email,
            given_name: values.firstName,
          },
          autoSignIn: true,
        },
      });
      if (data) {
        setIsLoading(false);
        toast.success(
          "Please verify your self first, we have sent the verification link on your email"
        );
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
        // navigate("/verification", {
        //   state: { email: values.email, password: values.password },
        // });
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signup error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Item elevation={4} style={{ boxShadow: "none" }}>
        <Box>
          <Badge
            color="secondary"
            sx={{ mt: 5, mb: 7 }}
            style={{
              cursor: "pointer",
              marginTop: "0px",
              marginBottom: "10px",
            }}
            onClick={() => navigate("/")}
          >
            <Typography
              sx={{ fontSize: { xs: "35px", md: "35px" }, color: "#5f5e5e" }}
            >
              pra
              <span style={{ color: "#02a1ff" }}>x</span>
              is/ai
            </Typography>
            {/* <Chip
                label="BETA"
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  color: "#0557FA",
                  fontSize: { xs: "20px", md: "28px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  border: "none",
                }}
              /> */}
          </Badge>
        </Box>
        <Box>
          <Typography variant="h5" sx={{ textAlign: "center" }} color="#0376BA">
            Sign up
          </Typography>
        </Box>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <FormField name="firstName" label="First Name" type="firstName" />
              <FormField name="email" label="Email" type="email" />
              <FormField name="password" label="Password" type="password" />
              <Box display="flex" mt={3} mb={3}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={
                    <p style={{ textAlign: "left" }}>
                      {`I agree to the `}
                      <Link
                        href="https://www.hellopraxis.com/privacy"
                        target="_blank"
                        style={{ color: "#0376BA" }}
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "20px",
                  margin: "10px 0 30px",
                }}
              >
                <PrimaryButton variant="contained" type="submit">
                  {isLoading ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    "Sign Up"
                  )}
                </PrimaryButton>
              </Box>
            </Form>
          </Formik>
          <Box>
            <Box
              onClick={() => navigate("/forgot-password")}
              sx={{
                cursor: "pointer",
                fontSize: "16px",
                color: "#0376ba",
                fontWeight: "bold",
                mt: 2,
                mb: 3,
              }}
            >
              Forgot password?
            </Box>
            <Box
              sx={{
                textAlign: "center",
                cursor: "pointer",
                color: "#0376ba",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              onClick={() => navigate("/signin")}
            >
              Already have an account
            </Box>
          </Box>
        </Box>
      </Item>
    </Grid>
  );
}
