import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import PrivateContainer from "./router/PrivateContainer";
import PublicContainer from "./router/PublicContainer";
import { ROUTES } from "./router";
import AppUrlListener from "./Components/AppUrlListener";

function App() {
  return (
    <>
      <AppUrlListener />
      <Router>
        <Routes>
          {ROUTES.map(
            ({ Component, isPrivate, path, tabTitle, isDashboard }) => (
              <Route
                exact
                path={path}
                key={path}
                element={
                  isPrivate ? (
                    <PrivateContainer
                      tabTitle={tabTitle}
                      isDashboard={isDashboard}
                    >
                      <Component />
                    </PrivateContainer>
                  ) : (
                    <PublicContainer tabTitle={tabTitle}>
                      <Component />
                    </PublicContainer>
                  )
                }
              />
            )
          )}
        </Routes>
      </Router>
    </>
  );
}

export default App;
