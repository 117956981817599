import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className="loadingSpinner">
      <div style={{ width: "125px", height: "125px", position: "relative" }}>
        {/* <img
  src={logo}
  alt=""
  style={{
    width: "124px",
    height: "124px",
    position: "absolute",
  }}
  /> */}
        <CircularProgress
          size={100}
          // color={"#017101"}
          style={{ width: "inherit", height: "inherit" }}
        />
      </div>
    </div>
  );
};

export default Loader;
