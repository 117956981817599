import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const columns1 = [
  {
    field: "email",
    headerName: "Email",
  },
  {
    field: "total_token",
    headerName: "Total Token",
  },
];

const columns2 = [
  {
    field: "email",
    headerName: "Email",
    maxWidth: 200,
  },
  {
    field: "token",
    headerName: "Token",
    maxWidth: 100,
  },
  {
    field: "answer",
    headerName: "Answer",
    filterable: false,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {String(params.value)}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: "question",
    headerName: "Question",
    filterable: false,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {params.value}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: "created_at",
    label: "CreatedAt",
    maxWidth: 200,
  },
];

function UserHistory() {
  const [userOverview, setUserOverview] = useState([]);
  const [userData, setUserData] = useState([]);

  const [open, setOpen] = useState(true);
  const [password, setPassword] = useState("");
  const [isAccess, setIsAccess] = useState(false);
  const correctPassword = "Password@123"; // Change this to your desired password
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password === correctPassword) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/users_history`)
        .then(({ data }) => {
          setUserData(
            data.sort(
              (a, b) =>
                new Date(b.created_at || null) - new Date(a.created_at || null)
            )
          );
          const grouped = data
            // .filter((item) => item.email)
            .reduce((result, currentItem) => {
              const email = currentItem.email;
              if (!result[email]) {
                result[email] = { email, arr: [] };
              }
              result[email].arr.push(currentItem);
              return result;
            }, {});
          const grouped2 = Object.values(grouped).map((item) => ({
            email: item.email,
            total_token: item.arr.reduce(
              (prev, curr) => prev + Number(curr.token),
              0
            ),
          }));
          setUserOverview(Object.values(grouped2));
        });
      setIsAccess(true);
      handleClose();
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        mt: 10,
        padding: "16px",
        height: "80vh",
        overflow: "auto",
      }}
    >
      {isAccess ? (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="User overview" value="1" />
              <Tab label="User Q&A history" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                mb: 2,
                textAlign: "center",
              }}
            >
              User Overview
            </Typography>
            <DataGrid
              rows={userOverview.map((it, i) => ({ ...it, id: i }))}
              columns={columns1.map((it) => ({ ...it, flex: 1 }))}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </TabPanel>
          <TabPanel value="2">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                mb: 2,
                textAlign: "center",
              }}
            >
              User History List
            </Typography>
            <DataGrid
              rows={userData.map((it, i) => ({ ...it, id: i }))}
              columns={columns2.map((it) => ({ ...it, flex: 1 }))}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </TabPanel>
        </TabContext>
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="modal-title">Enter Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter Password to access this page
            </DialogContentText>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              sx={{ mt: 3 }}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default UserHistory;
