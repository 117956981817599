import React, { useState } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";

import { setUser } from "../redux/reducer";
import Header from "../Components/Header";
import Loader from "../Components/Loader";

const PrivateContainer = ({ children, tabTitle, isDashboard }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      const data = await fetchUserAttributes();
      setIsLoading(false);
      if (data) {
        dispatch(setUser(data));
        if (data["custom:stripeCusId"]) {
          try {
            const { data: resData } = await axios.post(
              `${process.env.REACT_APP_API_URL}/stripe/customers`,
              { custId: data["custom:stripeCusId"] }
            );
            dispatch(setUser({ ...data, subscription: resData.data }));
          } catch (error) {
            console.log(error);
          }
          return;
        } else {
          return navigate("/subscription");
        }
      } else {
        return navigate("/signin");
      }
    } catch {
      setIsLoading(false);
      return navigate("/");
    }
  };

  useEffect(() => {
    if (tabTitle) document.title = tabTitle;
  }, [tabTitle]);

  useEffect(() => {
    checkAuth();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        overflow: isDashboard ? "hidden" : "auto",
        position: "relative",
        background: "#FDFDFD",
      }}
    >
      <Header menu={user ? false : true} />
      {children}
    </Box>
  );
};

export default PrivateContainer;
