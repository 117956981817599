import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

import { setIsNewChatModel } from "../redux/reducer";
import StartNewChat from "./Main/StartNewChat";
import Sidebar from "../Components/Sidebar";
import MufgLOGO from "../assets/Images/mufg.png";
import AIModelSelect from "./AIModelSelect";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const isChatIcon = useSelector((state) => state.isChatIcon);
  const chatHistory = useSelector((state) => state.chatHistory);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);
  // const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const user = useSelector((state) => state.user);
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openAIAPIStatus, setOpenAIAPIStatus] = useState("none");

  useEffect(() => {
    axios
      .get(`https://status.openai.com/api/v2/status.json`)
      .then(({ data }) => {
        setOpenAIAPIStatus(data.status.indicator);
      });
  }, []);

  const handleClickOpen = () => {
    dispatch(setIsNewChatModel(true));
  };

  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: "rgba(255,255,255,0.9)",
        position: "absolute",
        width: "-webkit-fill-available",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard", { state: { tabValue: 0 } })}
        >
          <Typography sx={{ fontSize: "24px !important", color: "#1235f5" }}>
            {["UX", "meetingAssistant", "default"].includes(typeOfAg) ? (
              "praxis/ai"
            ) : typeOfAg === "MUFG SuperChat" ? (
              <div style={{ width: "100px" }}>
                <img src={MufgLOGO} alt="MufgLOGO" />
              </div>
            ) : (
              agentList.find((it) => it.agentName === typeOfAg)?.agentLabel
            )}
          </Typography>
          <Chip
            label={user?.subscription?.planName}
            variant="filled"
            color="primary"
            size="small"
            sx={{ ml: 1 }}
          />
          {user["custom:stripeCusId"] && (
            <Box
              sx={{
                display: "flex",
                padding: { md: "6px 12px", xs: "4px 10px" },
                borderRadius: 0,
                cursor: "pointer",
                ml: 1,
              }}
            >
              <AIModelSelect
                openAIAPIStatus={openAIAPIStatus}
                inputProps={{
                  IconComponent: () => null,
                  sx: {
                    paddingRight: "12px !important",
                  },
                }}
                size="small"
                sx={{
                  color: (theme) => theme.palette.grey.gray_500,
                  fontSize: { sm: "14px", md: "14px", xs: "12px" },
                  fontWeight: 700,
                  "&:hover": {
                    "&& fieldset": {
                      border: "1.5px solid #193bf5",
                    },
                  },
                  border: "1px solid #193bf5",
                  borderRadius: "25px",
                }}
              />
            </Box>
          )}
        </Box>

        {typeOfAg === "UX" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #193bf5",
              padding: { md: "6px 12px", xs: "4px 10px" },
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.grey.gray_500,
                fontSize: { md: "14px", xs: "12px" },
                fontWeight: 700,
              }}
            >
              UX Assistant
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          {isChatIcon && chatHistory.length !== 0 && (
            <Button
              variant="text"
              sx={{
                borderRadius: "50px",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: { md: "14px", xs: "10px" },
                fontWeight: 500,
                textTransform: "capitalize",
                minWidth: { xs: "0px" },
              }}
              endIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              {isMdScreen && "New Chat"}
            </Button>
          )}

          <Box>
            <IconButton
              onClick={() => setDrawer(true)}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "50px",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <Sidebar
          setDrawer={setDrawer}
          handleClickOpen={handleClickOpen}
          drawer={drawer}
          openAIAPIStatus={openAIAPIStatus}
        />
      </Box>
      <Box>
        <StartNewChat fnCall={() => setDrawer(false)} />
      </Box>
    </Box>
  );
};

export default Header;
