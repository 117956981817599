import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { signIn, signInWithRedirect } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import {
  Box,
  Paper,
  Typography,
  Link,
  Grid,
  CircularProgress,
  Chip,
  Badge,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";

import { PrimaryButton } from "../../assets/theme/overrides/button";
import FormField from "../../assets/theme/overrides/FormField";

const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Item = styled(Paper)(({ theme }) => ({
  // borderRadius: "20px",
  padding: "30px 55px",
  width: "100%",
  maxWidth: "400px",
  textAlign: "center",
  // backgroundColor: "#F9F9FA",
  // borderRadius: "4px",
  // boxShadow: "none",
  // "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.3), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down("sm")]: {
    width: "320px",
    padding: "24px 12px",
    // boxShadow: "none",
    // backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}));

export default function SignIn() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState(false);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          navigate("/dashboard");
          break;
        case "signInWithRedirect_failure":
          console.log("signInWithRedirect_failure", payload);
          break;
        case "customOAuthState":
          console.log(payload.data); // this is the customState provided on signInWithRedirect function
          break;
        default:
          break;
      }
    });

    return unsubscribe;
  }, []);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await signIn({
        username: values.email,
        password: values.password,
      });
      if (data) {
        setIsLoading(false);
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signin error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  const loginemail = () => {
    setLoginEmail(true);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Item elevation={4} style={{ boxShadow: "none", borderRadius: "20px" }}>
          <Box>
            <Badge
              color="secondary"
              style={{ marginTop: "0px", marginBottom: "20px" }}
              sx={{ mt: 5, mb: 7 }}
            >
              <Typography
                sx={{ fontSize: { xs: "35px", md: "35px" }, color: "#5f5e5e" }}
              >
                pra
                <span style={{ color: "#02a1ff" }}>x</span>
                is/ai
              </Typography>
              {/* <Chip
                label="BETA"
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  color: "#0557FA",
                  fontSize: { xs: "20px", md: "28px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  border: "none",
                }}
              /> */}
            </Badge>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loginEmail ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <Box
                    sx={{
                      width: "300px",
                    }}
                  >
                    <FormField name="email" label="Email" type="email" />
                    <FormField
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "30px 0",
                      width: "100%",
                    }}
                  >
                    <PrimaryButton
                      variant="contained"
                      disabled={isLoading}
                      type="submit"
                      sx={{ width: "100%" }}
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        "Sign In"
                      )}
                    </PrimaryButton>
                  </Box>

                  <Box
                    onClick={() => navigate("/forgot-password")}
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px",
                      color: "#0376ba",
                      fontWeight: "bold",
                      mt: 2,
                    }}
                  >
                    Forgot password?
                  </Box>
                </Form>
              </Formik>
            ) : null}

            {!loginEmail && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 1,
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <Button
                    onClick={() => signInWithRedirect({ provider: "Apple" })}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      border: "2px solid rgba(226, 232, 240, 1)",
                      borderRadius: "50px",
                      px: 3,
                      py: 2,
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#1e293b",
                      opacity: 1,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "2px solid rgba(30, 41, 59, 1)",
                      },
                    }}
                    startIcon={
                      <Icon icon="devicon:apple" style={{ fontSize: "20px" }} />
                    }
                  >
                    Sign in with Apple
                  </Button>

                  <Button
                    onClick={() => signInWithRedirect({ provider: "Google" })}
                    variant="outlined"
                    sx={{
                      mt: 2,
                      mb: loginEmail ? 8 : 2,
                      width: "100%",
                      border: "2px solid rgba(226, 232, 240, 1)",
                      borderRadius: "50px",
                      px: 3,
                      py: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#1e293b",
                      opacity: 1,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "2px solid rgba(30, 41, 59, 1)",
                      },
                    }}
                    startIcon={
                      <Icon
                        icon="flat-color-icons:google"
                        style={{ fontSize: "20px" }}
                      />
                    }
                  >
                    Sign in with Google
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: loginEmail ? 10 : 4,
                    cursor: "pointer",
                    color: "#0376ba",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  onClick={() => loginemail()}
                >
                  Login with email
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: loginEmail ? 4 : 4,
                cursor: "pointer",
                color: "#0376ba",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              onClick={() => navigate("/signup")}
            >
              Register here
            </Box>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
