import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user["custom:stripeCusId"]) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <Box
      sx={{
        mt: 10,
        padding: "16px",
      }}
    >
      {/* <Typography
        variant="h2"
        sx={{
          pt: 3,
          pb: 3,
          textAlign: "center",
          backgroundColor: "rgb(82, 95, 127)",
          color: "white",
        }}
      >
        We have offer a plan that helps any business to get there
      </Typography> */}
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_KEY}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISH_KEY}
        client-reference-id={user.sub}
        customer-email={user.email}
      ></stripe-pricing-table>
    </Box>
  );
}

export default Subscription;
