import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { setPipelineTypeDoc, setSelectedAIModel } from "../redux/reducer";

export const AIModelList = [
  {
    model: "gpt-3.5-turbo",
    name: "gpt-3.5-turbo",
    provider: "openAI",
    dec: "A fast, inexpensive model for simple tasks",
  },
  {
    model: "gpt-4-turbo",
    name: "gpt-4-turbo",
    provider: "openAI",
    dec: "The previous set of high-intelligence models",
  },
  {
    model: "gpt-4o",
    name: "gpt-4o",
    provider: "openAI",
    dec: "high-intelligence flagship model for complex, multi-step tasks",
  },
  {
    model: "gpt-4o-mini",
    name: "gpt-4o-mini",
    provider: "openAI",
    dec: "affordable and intelligent small model for fast, lightweight tasks",
  },
  {
    model: "o1-preview",
    name: "gpt-o1-preview",
    provider: "openAI",
    dec: "Language models trained with reinforcement learning to perform complex reasoning.",
  },
  {
    model: "us.anthropic.claude-3-5-sonnet-20241022-v2:0",
    name: "Claude 3.5 Sonnet",
    provider: "aws",
    dec: "The upgraded Claude 3.5 Sonnet is now state-of-the-art for a variety of tasks including real-world software engineering, agentic capabilities and computer use. The new Claude 3.5 Sonnet delivers these advancements at the same price and speed as its predecessor.",
  },
  {
    model: "us.meta.llama3-2-90b-instruct-v1:0",
    name: "Llama 3.2",
    provider: "aws",
    dec: "Instruction-tuned image reasoning generative model (text + images in / text out) optimized for visual recognition, image reasoning, captioning and answering general questions about the image.",
  },
  {
    model: "mistral.mistral-large-2402-v1:0",
    name: "Mistral AI large",
    provider: "aws",
    dec: "The most advanced Mistral AI Large Language model capable of handling any language task including complex multilingual reasoning, text understanding, transformation, and code generation.",
  },
  {
    model: "amazon.nova-pro-v1:0",
    name: "Nova Pro",
    provider: "aws",
    dec: "Nova Pro is a multimodal understanding foundation model. It is multilingual and can reason over text, images and videos.",
  },
  {
    model: "amazon.nova-lite-v1:0",
    name: "Nova Lite",
    provider: "aws",
    dec: "Nova Lite is a is a multimodal understanding foundation model. It is multilingual and can reason over text, images and videos.",
  },
  {
    model: "gemini-1.5-pro",
    name: "Gemini 1.5 Pro",
    provider: "google",
    dec: "A multimodal model that supports adding image, audio, video, and PDF files in text or chat prompts for a text or code response. This model supports long-context understanding up to the maximum input token limit.",
  },
  {
    model: "gemini-1.5-flash",
    name: "Gemini 1.5 Flash",
    provider: "google",
    dec: "Provides speed and efficiency for high-volume, quality, cost-effective apps.",
  },
  {
    model: "gemini-2.0-flash-exp",
    name: "Gemini 2.0 Flash",
    provider: "google",
    dec: "Provides speed and efficiency for high-volume, quality, cost-effective apps.",
  },
];

function AIModelSelect({ openAIAPIStatus, ...rest }) {
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const dispatch = useDispatch();

  const onModelChanges = (e) => {
    const val = e.target.value;
    dispatch(setSelectedAIModel(val));
    if (val.provider === "openAI" && val.model !== "gpt-3.5-turbo") {
      dispatch(setPipelineTypeDoc("openaiFile"));
    } else {
      dispatch(setPipelineTypeDoc("langchain"));
    }
  };

  return (
    <Select
      fullWidth
      value={selectedAIModel}
      onChange={onModelChanges}
      {...rest}
    >
      {AIModelList.map((item, i) => (
        <MenuItem value={item} key={i}>
          <Tooltip title={item.dec} placement="right" arrow>
            {item.provider === "openAI" && (
              <Badge
                sx={{ mr: 1 }}
                color={
                  openAIAPIStatus === "none"
                    ? "success"
                    : openAIAPIStatus === "minor"
                    ? "warning"
                    : "error"
                }
                variant="dot"
              />
            )}
            <span>{item.name}</span>
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
}

export default AIModelSelect;
